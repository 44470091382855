<template>
  <div class="event">
    <Header
      :info="state.event"
      :alignType="state.event.type"
      :actions="state.actions"
      :url="state.event.url"
    />
    <div v-if="state.event.type === 4" class="coupon-content">
      <div class="title">優惠好康</div>
      <Coupon
        v-for="(coupon, idx) in couponList"
        :key="idx"
        :coupon="coupon"
        :callBack="getCoupon"
        :width="outerWidth"
        :height="outerHeight"
      />
    </div>
    <div v-html="state.event.content" class="event-content"></div>
    <Notice
      title="領取"
      :isSuccess="isSuccess"
      class="notice"
      :class="{ noticeShow }"
      @close-notice="closeNotice"
    />
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Coupon from '@/components/coupon.vue'
import Notice from '@/components/notice.vue'
import { ref, reactive, onMounted } from 'vue'
import api from '@/api/api'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { openLoading, closeLoading } from '../helpers/loading.js'
import { openOverlay, closeOverlay } from '../helpers/overlayShow.js'

export default {
  name: 'Event',
  components: {
    Header,
    Coupon,
    Notice,
  },
  data() {
    return {
      headerInfo: {
        images: [require('@/assets/dev/event-pic2.jpg')],
        title: '安東廳「布列塔尼藍龍蝦」',
        subTitle: '限量供應，需於用餐前五日預訂。',
        detail:
          '中文假字約70字...人我念總海我要中頭：頭無候詩影著告血來以臺趣復正完使，術前轉作身一力思們人裡速色民價出回遊臺比雖此命正職。們情沒來們許成，體死能和場蘭人。利民上上力的漸高，智用館是，向成實分別到。',
        actions: [],
      },
      eventType: 1,
      eventContent:
        "<p>為回饋忠實饕客的愛護與支持，探索廚房、寒舍食譜、北緯二十五及巧克光廊於2021年與滙豐銀行、中國信託銀行、台新銀行、新光銀行、國泰世華銀行、花旗銀行、美國運通銀行、第一銀行、JCB合作，提供以下信用卡優惠，讓賓客可以更實惠方式，暢饗中西經典佳餚。<br><br><a href='https://drive.google.com/file/d/10mtZkIfxUAd6KJlvl8MrMKYtSIVfeRWw/view?usp=sharing'>探索廚房－信用卡合作與規範</a><br>．滙豐銀行、中國信託銀行、台新銀行、新光銀行、國泰世華銀行、花旗銀行、美國運通銀行、第一銀行<br>．平假日用餐75折起，最優回饋享5折<br><br><a href='https://drive.google.com/file/d/12Rwmpj1R3s3zaQxqNdh1atJwggGqcAiD/view?usp=sharing'>寒舍食譜－信用卡合作與規範</a><br>．滙豐銀行、中國信託銀行、台新銀行、新光銀行、國泰世華銀行、花旗銀行、美國運通銀行、第一銀行<br>．平假日用餐75折起，最優回饋享5折<br><br><a href='https://drive.google.com/file/d/1FrX2UB6eSvxP-qAmVEkv4EPUFEbL6K5T/view?usp=sharing'>北緯二十五－信用卡合作與規範</a><br>．滙豐銀行、中國信託銀行、台新銀行、新光銀行、國泰世華銀行、花旗銀行、美國運通銀行、第一銀行<br>．平假日用餐75折起，最優回饋享5折<br><br><a href='https://drive.google.com/file/d/1EB966c2SFeYhIlxAtaPPDHTNHH38h-HL/view?usp=sharing'>巧克光廊－信用卡合作與規範</a><br>．滙豐銀行、中國信託銀行、台新銀行、新光銀行、國泰世華銀行、花旗銀行、美國運通銀行、JCB<br>．平假日全日營業時段享85折<br></p>",
    }
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      event: {},
      actions: [],
    })

    const outerWidth = ref(0)
    const outerHeight = ref(0)
    onMounted(() => {
      const width = window.innerWidth - 40
      outerWidth.value = width > 335 ? 335 : width
      const height = Math.floor((window.innerWidth - 40) * (96 / 335))
      outerHeight.value = height > 96 ? 96 : height
    })
    window.onresize = () => {
      const width = window.innerWidth - 40
      outerWidth.value = width > 335 ? 335 : width
      const height = Math.floor((window.innerWidth - 40) * (96 / 335))
      outerHeight.value = height > 96 ? 96 : height
    }

    openOverlay()
    openLoading()
    api
      .ActivityInfo({ activityId: route.params.eid })
      .then(result => {
        state.event = result.data
        if (state.event.type == 1) {
          state.actions.push({
            actionName: '住宿線上預訂',
            action: () => {
              window.openUrlExternal(state.event.url)
            },
          })
        } else if (state.event.type == 2) {
          window.inlineAuthTokenCallback = value => {
            store.commit('m_setInleinTokenInfo', value)
          }

          if (typeof window.host_getInlineAuthToken !== 'undefined') {
            window.host_getInlineAuthToken('inlineAuthTokenCallback')
          }

          state.actions.push({
            actionName: '美饌線上預訂',
            action: () => {
              const inleinTokenInfo = store.state.inleinTokenInfo
              const url = state.event.url
              const link = url.includes('inline')
                ? `${url}&pre_filled_form=${inleinTokenInfo}`
                : url
              window.openUrlExternal(link)
            },
          })
        } else if (state.event.type == 4) {
          window.userAuthTokenCallback = value => {
            store.commit('m_setUserAuthToken', value)
          }

          if (typeof window.host_getUserAuthToken !== 'undefined') {
            window.host_getUserAuthToken('userAuthTokenCallback')
          }

          if (store.state.userAuthToken) {
            getDiscountActivity()
          } else {
            if (typeof window.host_getUserAuthToken !== 'undefined') {
              window.host_getUserAuthToken('userAuthTokenCallback')
              setTimeout(() => {
                getDiscountActivity()
              }, 300)
            }
          }
        }
        closeOverlay()
        closeLoading()
      })
      .catch(err => {
        console.error('取得活動失敗', err)
        closeOverlay()
        closeLoading()
      })

    const couponList = ref([])
    const getDiscountActivity = () => {
      openOverlay()
      openLoading()
      const userAuthToken = store.state.userAuthToken
      api
        .DiscountActivity(
          { activityId: Number(route.params.eid) },
          userAuthToken
        )
        .then(result => {
          couponList.value = result.data
          closeOverlay()
          closeLoading()
        })
        .catch(err => {
          console.error('取得優惠失敗', err)
          closeOverlay()
          closeLoading()
        })
    }

    const isSuccess = ref(false)
    const noticeShow = ref(false)
    const getCoupon = (discountManageId, couponCode) => {
      openOverlay()
      openLoading()
      api
        .GetCoupon({
          discountManageId,
          couponCode,
          activityId: Number(route.params.eid),
        })
        .then(result => {
          noticeShow.value = true

          if (result.code === 1000) {
            isSuccess.value = true
            couponList.value.forEach(item => {
              if (
                item.discountManageId === discountManageId &&
                item.couponCode === couponCode
              ) {
                item.status = 2
              }
            })
          }
          closeLoading()
        })
        .catch(err => {
          noticeShow.value = true
          console.error('兌換優惠失敗', err)
          closeLoading()
        })
    }

    const closeNotice = () => {
      noticeShow.value = false
      closeOverlay()
    }

    return {
      state,
      outerWidth,
      outerHeight,
      couponList,
      isSuccess,
      noticeShow,
      getCoupon,
      closeNotice,
    }
  },
}
</script>
<style scoped lang="scss">
.event-content {
  padding: 32px 5.33% 99px;
  min-height: calc(100vh - 72px);
  .title {
    padding-bottom: 17px;
  }
}

.coupon-content {
  padding: 16px 20px;

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #ceb17d;
    margin-bottom: 12px;
  }
}

.notice {
  position: fixed;
  bottom: -300px;
  transition: bottom 0.5s ease;

  &.noticeShow {
    bottom: 0;
  }
}
</style>
