<template>
  <div class="notice">
    <div class="title">
      <div class="image" @click="closeNotice">
        <img :src="IconCross" alt="" />
      </div>
      <div class="name">{{ title }}</div>
    </div>
    <div class="content">
      <img :src="IconStatus" alt="" />
      <div :class="{ isSuccess }">{{ statusText }}</div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import IconCross from '../assets/img/icon/close_black.svg'
import IconSuccess from '../assets/img/icon/success.svg'
import IconError from '../assets/img/icon/error.svg'

export default {
  name: 'Notice',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  emits: ['close-notice'],
  setup(props, { emit }) {
    const IconStatus = computed(() => {
      return props.isSuccess ? IconSuccess : IconError
    })
    const statusText = computed(() => {
      return props.isSuccess ? '領取成功' : '領取失敗'
    })
    const closeNotice = () => {
      emit('close-notice')
    }

    return {
      statusText,
      IconCross,
      IconStatus,
      closeNotice,
    }
  },
}
</script>

<style scoped lang="scss">
.notice {
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  background: #fefdfb;
  width: 100%;
  max-height: 250px;
  padding: 24px 16px 45px 16px;
  position: relative;
  z-index: 1100 !important;
  position: relative;
  bottom: 0;
  left: 0;

  .title {
    position: relative;
    display: flex;
    .image {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      left: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .name {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      font-weight: bold;
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      color: #222222;
    }
  }

  .content {
    text-align: center;
    margin-top: 24px;

    img {
      margin: 0 auto;
    }

    & > div {
      margin-top: 16px;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #a66653;

      &.isSuccess {
        color: #9caf88;
      }
    }
  }
}
</style>
