<template>
  <div class="coupon">
    <div class="content">
      <div class="info-block">
        <div class="title">{{ coupon.couponDisplayName }}</div>
        <div class="subtitle">{{ coupon.couponProfile }}</div>
      </div>
      <div class="btn-bluck" @click="handleClick">
        <div class="btn" :class="{ disabled }">{{ btnText }}</div>
      </div>
    </div>
    <svg
      :width="couponWidth"
      :height="couponHeight"
      viewBox="0 0 335 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M246 0C243.791 0 242.083 1.97495 240.716 3.71021C239.617 5.10469 237.913 6 236 6C234.087 6 232.383 5.10469 231.284 3.71021C229.917 1.97495 228.209 0 226 0H8C3.58172 0 0 3.58172 0 8V88C0 92.4183 3.58172 96 8 96H226C228.209 96 229.917 94.0251 231.284 92.2898C232.383 90.8953 234.087 90 236 90C237.913 90 239.617 90.8953 240.716 92.2898C242.083 94.0251 243.791 96 246 96H327C331.418 96 335 92.4183 335 88V8C335 3.58172 331.418 0 327 0H246Z"
        :fill="`#${coupon.couponColor}`"
      />
      <line
        x1="237.5"
        y1="16.5"
        x2="237.5"
        y2="80.5"
        stroke="white"
        stroke-opacity="0.5"
        stroke-width="3"
        stroke-linecap="round"
        stroke-dasharray="0.1 9"
      />
    </svg>
  </div>
</template>

<script>
import { computed } from 'vue'

const BUTTON_STATUS = ['立即領取', '已領取', '發放完畢', '已過期']

export default {
  name: 'Coupon',
  components: {},
  props: {
    coupon: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    callBack: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const btnText = computed(() => {
      return BUTTON_STATUS[props.coupon.status - 1]
    })

    const couponWidth = computed(() => {
      return props.width
    })

    const couponHeight = computed(() => {
      return props.height
    })

    const disabled = computed(() => {
      return props.coupon.status !== 1
    })

    const handleClick = () => {
      if (!disabled.value) {
        props.callBack(props.coupon.discountManageId, props.coupon.couponCode)
      }
    }

    return { couponWidth, couponHeight, btnText, disabled, handleClick }
  },
}
</script>

<style scoped lang="scss">
.coupon {
  position: relative;
  .content {
    width: 100%;
    height: calc((96 / 335) * (100vw - 40px));
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;

    .info-block {
      width: calc((238 / 335) * (100vw - 40px));
      text-align: left;
      box-sizing: border-box;
      padding: 16px;

      .title,
      .subtitle {
        color: #ffffff;
      }
      .title {
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.32px;
        font-weight: bold;
      }

      .subtitle {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.32px;
        color: rgba(255, 255, 255, 0.8);
        white-space: inherit;
        overflow: hidden;
        overflow-wrap: unset;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .btn-bluck {
      width: calc((96 / 335) * (100vw - 40px));
      height: calc((96 / 335) * (100vw - 40px));
      display: flex;
      justify-content: center;
      align-items: center;
      background: url('../assets/img/icon/mhh-logo.svg') no-repeat center center /
        80% 80%;

      .btn {
        box-sizing: border-box;
        padding: 4px 12px;
        font-size: 13px;
        line-height: 19px;
        color: #9d8147;
        background: #ffffff;
        border-radius: 100px;

        &.disabled {
          color: #ffffff;
          background: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  svg {
    width: calc(100vw - 40px);
    position: absolute;
    top: 0;
    z-index: 1;
  }
}

.coupon ~ .coupon {
  margin-top: 8px;
}

@media (min-width: 375px) {
  .coupon {
    .content {
      width: 335px;
      height: 96px;
      margin: 0 auto;

      .info-block {
        width: calc((238 / 335) * 335px);
      }

      .btn-bluck {
        width: 96px;
        height: 96px;
      }
    }
  }
}
</style>
